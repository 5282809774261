import type { FilterDefinitions } from '@bottlebooks/gatsby-theme-event/src/components/Filters/useIndex';
import { defineMessage } from '@lingui/macro';
import { graphql as gatsbyGraphQL } from 'gatsby';

// TODO generate this automatically from the fragment.
interface Product {
  vintage?: string;
  grapeVarieties?: ({ varietyName?: string | null } | null)[] | null;
  productType?: string | null;
  wineType?: string | null;
  classification?: string | null;
  characteristics?: (string | null)[] | null;
  specialClassification?: string | null;
  designation?: string | null;
  country?: string | null;
  region?: string | null;
  subregion?: string | null;
  wineColor?: string | null;
  producer?: { sortName?: string | null; name?: string | null } | null;
  pricing?: {
    priceRange?: string | null;
    price?: number | null;
    currency?: string | null;
  } | null;
  regionHierarchy?: (string | null)[] | null;
}

export default function getProductFilters(): FilterDefinitions<Product> {
  return {
    aggregations: {
      // productType: {
      //   title: defineMessage({ message: 'Product types' }),
      //   conjunction: false,
      //   get: (product: Product) =>
      //     product.wineType ||
      //     // product.spiritType ||
      //     // product.beerType ||
      //     product.productType,
      //   size: 20,
      //   translations: {
      //     WINE: defineMessage({ message: 'Other wine' }),
      //     SPIRIT: defineMessage({ message: 'Spirit' }),
      //     Stillwein: defineMessage({ message: 'Wine' }),
      //     BEER: defineMessage({ message: 'Beer and more' }),
      //   },
      // },
      country: {
        title: defineMessage({ message: 'Countries' }),
        conjunction: false,
        multiSelect: false,
        size: 100,
        get: (product) => product.country || undefined, // Excludes nulls
      },
      region: {
        title: defineMessage({ message: 'Wine regions' }),
        conjunction: false,
        multiSelect: false,
        size: 100,
        get: (product) => product.region || undefined, // Excludes nulls
      },
      subregion: {
        title: defineMessage({ message: 'Appellations' }),
        conjunction: false,
        multiSelect: false,
        size: 100,
        get: (product) => product.subregion || undefined, // Excludes nulls
      },
      wineColor: {
        title: defineMessage({ message: 'Color' }),
        conjunction: false,
        multiSelect: false,
        size: 100,
        get: (product) => product.wineColor || undefined, // Excludes nulls
      },
      // regionHierarchy: {
      //   title: defineMessage({ message: 'Winegrowing regions' }),
      //   conjunction: false,
      //   // multiSelect: false,
      //   size: 100,
      //   get: (product) => product.regionHierarchy?.map((e) => e || '') || [], // Excludes nulls
      // },
      vintage: {
        title: defineMessage({ message: 'Vintages' }),
        conjunction: false,
        size: 50,
        sort: 'term',
        order: 'desc',
        get: (product: Product) => {
          return product.vintage || undefined;
        }, // Excludes nulls
        translations: {
          NV: defineMessage({ message: 'NV' }),
        },
      },
      priceRanges: {
        title: defineMessage({ message: 'Price ranges' }),
        conjunction: false,
        sort: 'term',
        // This comes from ProductsTemplate.afterQuery.
        get: (product: Product) => product.pricing?.priceRange || undefined, // Excludes nulls
      },
      productionMethods: {
        title: defineMessage({ message: 'Production methods' }),
        conjunction: false,
        size: 20,
        get: (product) =>
          product?.characteristics
            ?.filter((value) =>
              [
                'VEGAN',
                'ORGANIC',
                'VEGETARIAN',
                'BIODYNAMIC',
                'KOSHER',
                'SUSTAINABLE',
              ].includes(value || '')
            )
            .map((e) => e || '') || [],
        translations: {
          VEGAN: defineMessage({ message: 'vegan' }),
          ORGANIC: defineMessage({ message: 'organic' }),
          VEGETARIAN: defineMessage({ message: 'vegetarian' }),
          BIODYNAMIC: defineMessage({ message: 'biodynamic' }),
          KOSHER: defineMessage({ message: 'kosher' }),
          SUSTAINABLE: defineMessage({ message: 'sustainable' }),
        },
      },

      classification: {
        title: defineMessage({ message: 'Classifications' }),
        conjunction: false,
        size: 50,
        get: (product) => {
          if (!product?.classification) return undefined; // Excludes nulls
          if (product.classification === '----') return undefined;
          if (product.classification === 'No classification') return undefined;
          return product.classification;
        },
      },
      specialClassification: {
        title: defineMessage({ message: 'Special classifications' }),
        conjunction: false,
        size: 50,
        get: (product) => {
          if (!product?.specialClassification) return undefined; // Excludes nulls
          if (product.specialClassification === '----') return undefined;
          return product.specialClassification;
        },
      },
      // designation: {
      //   title: defineMessage({ message: 'Designations' }),
      //   conjunction: false,
      //   size: 50,
      //   get: (product) => product.designation || undefined, // Excludes nulls
      // },
      grapeVarieties: {
        title: defineMessage({ message: 'Grape varieties' }),
        conjunction: false,
        size: 300,
        get: (product: Product) =>
          product.grapeVarieties
            ?.map((variety) => variety?.varietyName || '')
            .filter(Boolean), // Excludes nulls
      },
      // closureTypes: {
      //   title: defineMessage({ message: 'Closure types' }),
      //   conjunction: false,
      //   size: 50,
      //   get: (product) => product.bottleVariants?.[0]?.closureType || undefined, // Excludes nulls
      // },
    },

    sortings: {
      // byProducerName: {
      //   title: defineMessage({ message: 'Producer name' }),
      //   field: [
      //     (product) => product?.producer?.sortName || product?.producer?.name,
      //   ],
      //   order: 'asc',
      //   group: 'producer.producerId',
      //   groupHeader: 'producer',
      // },
      byProductName: {
        title: defineMessage({ message: 'Product name' }),
        field: ['shortName', 'vintage'],
        order: ['asc', 'desc'],
      },
      // byExhibitorName: {
      //   title: defineMessage({ message: 'Exhibitor name' }),
      //   field: [
      //     'exhibitor.sortName',
      //     'exhibitor.name',
      //     'producer.sortName',
      //     'producer.name',
      //   ],
      //   order: 'asc',
      //   group: 'exhibitorId',
      // },
      // byStandNumber: {
      //   title: defineMessage({ message: 'Table number' }),
      //   field: [
      //     sortBy.standName,
      //     'exhibitor.sortName',
      //     'exhibitor.name',
      //     'producer.sortName',
      //     'producer.name',
      //   ],
      //   order: 'asc',
      //   group: 'registration.registrationId',
      // },
    },
  };
}

// Sort helper functions
const sortBy = {
  standName: (product) =>
    product?.stand?.name
      .split(/[\s,-]+/)
      .map((segment) =>
        segment === 'Tisch'
          ? undefined
          : Number(segment)
          ? segment.padStart(3, '0')
          : segment
      )
      .filter(Boolean)
      .join(' ') || '999',
};

export const fragment = gatsbyGraphQL/* GraphQL */ `
  fragment productFilters on Bottlebooks_RegisteredProduct {
    registration {
      registrationId
      profile {
        sortName
        name
      }
    }
    product {
      name
      producer {
        producerId
        sortName
        name
        # For rendering the producer header
        countryName: country(format: LOCALIZED)
        countryCode: country(format: RAW)
        city
      }
      productType
      ... on Bottlebooks_Wine {
        wineType
        characteristics
        classification
        vintage(removeNonVintage: true)
        denomination
        grapeVarieties {
          varietyName
        }
      }
      # ... on Bottlebooks_Beer {
      #   beerType
      # }
      ... on Bottlebooks_Spirit {
        spiritType
      }
    }
    registration {
      stand {
        name
      }
    }
  }
`;
