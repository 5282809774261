/** @jsxRuntime classic */
/** @jsx jsx */
import { gql } from '@apollo/client';
import { Button, DownloadIcon } from '@bottlebooks/gatsby-theme-base';
import { Trans } from '@lingui/macro';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';

export default function ProductInfoSheetButton({ product, ...rest }) {
  if (!product?.infoSheet?.url) return null;
  return (
    <Button
      href={product.infoSheet.url}
      target="_blank"
      rel="noopener noreferrer"
      variant="outline"
      sx={{ textAlign: 'center' }}
      {...rest}
    >
      <DownloadIcon
        size="small"
        sx={{ marginRight: 2, color: 'currentColor' }}
      />
      <Trans>Download info sheet</Trans>
    </Button>
  );
}

export const fragment = graphql`
  fragment bb_ProductInfoSheetButton_RegisteredProduct on Bottlebooks_RegisteredProduct {
    infoSheet(templateId: "BreakEvents/InfoSheetWithQRCodes") {
      url
    }
  }
  # This fragment should be removed after upgradign to latest theme
  fragment bb_ProductInfoSheetButton on Bottlebooks_Product {
    infoSheet {
      url
    }
  }
`;

ProductInfoSheetButton.fragment = gql`
  fragment ProductInfoSheetButton on RegisteredProduct {
    infoSheet(templateId: "BreakEvents/InfoSheetWithQRCodes") {
      url
    }
  }
`;
