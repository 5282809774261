/** @jsx jsx */
import {
  Box,
  ChevronRightIcon,
  Col,
  Container,
  Flex,
  Label,
  Link,
  Row,
  Title,
} from '@bottlebooks/gatsby-theme-base';
import { Trans } from '@lingui/macro';
import { graphql } from 'gatsby';
import chunk from 'lodash/chunk';
import { jsx } from 'theme-ui';
import useLink from '@bottlebooks/gatsby-theme-event/src/useLink';
import BrandCarousel from '@bottlebooks/gatsby-theme-event/src/components/BrandCarousel';
import useMenuQuery from '@bottlebooks/gatsby-theme-event/src/components/Layout/useMenuQuery';

export default function FeaturedExhibitorsSection({
  exhibitors,
  locale,
  ...rest
}) {
  const link = useLink();
  const menuItems = useMenuQuery({ locale });
  const exhibitorMenu = menuItems.find(({ type }) => type === 'exhibitors');
  const brandChunks = chunk(exhibitors, 2);
  return (
    <Box {...rest}>
      <SectionTitle
        to={link.exhibitors()}
        linkText={
          <Flex align="center">
            <Trans>Explore marketplace</Trans>
            <ChevronRightIcon size="xsmall" sx={{ marginLeft: 2 }} />
          </Flex>
        }
      ></SectionTitle>

      <BrandCarousel
        as="section"
        brandChunks={brandChunks}
        showIndicators
        sx={{ backgroundColor: 'white' }}
      />
    </Box>
  );
}

function SectionTitle({ children, to, linkText, ...rest }) {
  return (
    <Container sx={{ paddingY: [0, 2], marginBottom: 4 }} {...rest}>
      <Row direction={['column', 'row']} align="baseline">
        <Col flex>
          <Title sx={{ color: 'title' }}>{children}</Title>
        </Col>
        <Col>
          <Link to={to} sx={{ display: 'block' }}>
            <Label>{linkText}</Label>
          </Link>
        </Col>
      </Row>
    </Container>
  );
}

export const fragment = graphql`
  fragment FeaturedExhibitorsSection on Exhibitor {
    ...BrandCarousel
    ...useLink_exhibitor_AbstractBrand
  }
`;
