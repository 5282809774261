/** @jsx jsx */
import { Title } from '@bottlebooks/gatsby-design-system';
import { Plural } from '@lingui/macro';
import React from 'react';
import { jsx } from 'theme-ui';

export default function ExhibitorsPageTitle({ totalCount, count, ...rest }) {
  if (count === totalCount) {
    return (
      <Title {...rest}>
        <Plural
          value={totalCount}
          one={
            <React.Fragment>
              <Em>#</Em> profile
            </React.Fragment>
          }
          other={
            <React.Fragment>
              <Em>#</Em> profiles
            </React.Fragment>
          }
        />
      </Title>
    );
  }

  return (
    <Title {...rest}>
      <Plural
        value={totalCount}
        one={
          <React.Fragment>
            <Em>{count}</Em> of <Em>#</Em> profiles
          </React.Fragment>
        }
        other={
          <React.Fragment>
            <Em>{count}</Em> of <Em>#</Em> profiles
          </React.Fragment>
        }
      />
    </Title>
  );
}

export function Em({ children, ...rest }) {
  return (
    <em sx={{ color: 'primary', fontStyle: 'normal' }} {...rest}>
      {children}
    </em>
  );
}
