/** @jsx jsx */
import MenuItem from '@bottlebooks/gatsby-theme-event/src/components/Layout/MenuItem';
import useLink from '@bottlebooks/gatsby-theme-event/src/useLink';
import { Trans } from '@lingui/macro';
import React from 'react';
import { jsx } from 'theme-ui';

export default function MenuItems() {
  const link = useLink();
  return (
    <React.Fragment>
      <MenuItem type="exhibitors">
        <Trans>Explore marketplace</Trans>
      </MenuItem>
      <MenuItem url={link.vinomed()}>
        <Trans>Exhibitors</Trans>
      </MenuItem>
      <MenuItem type="products">
        <Trans>Discover wines</Trans>
      </MenuItem>
      <MenuItem url={link.services()}>
        <Trans>Wine tourism</Trans>
      </MenuItem>
    </React.Fragment>
  );
}
