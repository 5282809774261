import { defineMessage } from '@lingui/macro';
import { graphql as gatsbyGraphQL } from 'gatsby';
import type {
  FilterDefinitions,
  Aggregation,
} from '@bottlebooks/gatsby-theme-event/src/components/Filters/useIndex';
import { rankings } from 'match-sorter';
import useSiteConfig from '@bottlebooks/gatsby-theme-event/src/components/useSiteConfig';

// TODO generate this automatically from the fragment.
interface Exhibitor {
  countryName?: string | null;
  profile?: {
    hospitalityOfferings?: (string | null)[] | null;
    wineExperienceOfferings?: (string | null)[] | null;
    outdoorExperienceOfferings?: (string | null)[] | null;
    lifestyleOfferings?: (string | null)[] | null;
  } | null;
  stand?: { name?: string | null } | null;
  vinomedRegion?: { text?: string | null } | null;
  registrationType?: string | null;
}

export default function useExhibitorFilters(): FilterDefinitions<Exhibitor> {
  return {
    aggregations: {
      // Not sure why it appears when a vinomedRegion is selected.
      // Hiding until this can be resolved.
      // countryName: {
      //   title: defineMessage({ message: 'Countries' }),
      //   size: 100,
      //   conjunction: false,
      //   // Remove null values
      //   get: (exhibitor) => exhibitor.countryName || [],
      // },
      vinomedRegion: {
        title: defineMessage({ message: 'Region' }),
        size: 100,
        conjunction: false,
        // Remove null values
        get: (exhibitor) => exhibitor?.vinomedRegion?.text || [],
      },
      wineExperienceOfferings: {
        title: defineMessage({ message: 'Wine experiences' }),
        // multiSelect: false, // TODO Implement
        size: 20,
        sort: 'term',
        get: (exhibitor) =>
          exhibitor.profile?.wineExperienceOfferings?.filter(Boolean),
      },
      hospitalityOfferings: {
        title: defineMessage({ message: 'Hospitality services' }),
        // multiSelect: false, // TODO Implement
        size: 20,
        sort: 'term',
        get: (exhibitor) =>
          exhibitor.profile?.hospitalityOfferings?.filter(Boolean),
      },
      outdoorExperienceOfferings: {
        title: defineMessage({ message: 'Outdoor experiences' }),
        // multiSelect: false, // TODO Implement
        size: 20,
        sort: 'term',
        get: (exhibitor) =>
          exhibitor.profile?.outdoorExperienceOfferings?.filter(Boolean),
      },
      lifestyleOfferings: {
        title: defineMessage({ message: 'Lifestyle services' }),
        // multiSelect: false, // TODO Implement
        size: 20,
        sort: 'term',
        get: (exhibitor) =>
          exhibitor.profile?.lifestyleOfferings?.filter(Boolean),
      },
    },

    sortings: {
      // byStandName: {
      //   title: defineMessage({ message: 'Table number' }),
      //   field: [sortBy.standName, 'profile.sortName', 'name'],
      //   order: 'asc',
      // },
      // byDataCompleteness: {
      //   title: defineMessage({ message: 'By data completeness' }),
      //   field: [
      //     sortBy.standName,
      //     sortBy.dataCompleteness,
      //     'profile.sortName',
      //     'name',
      //   ],
      //   order: 'asc',
      // },
      byExhibitorName: {
        title: defineMessage({ message: 'Exhibitor name' }),
        field: ['profile.sortName', 'name'],
        order: 'asc',
      },
    },
    search: {
      threshold: rankings.WORD_STARTS_WITH,
      keys: [
        { key: 'name', threshold: rankings.CONTAINS },
        (exhibitor) =>
          `${exhibitor.countryName || ''} ${exhibitor.region || ''} ${
            exhibitor.city || ''
          }`.trim(),
        (exhibitor) => exhibitor?.stand?.name?.replace(/[()]/g, '') || '',
      ],
    },
  };
}

const completeness = {
  ORGANIZER_REVIEWED: 1,
  STEP_COMPLETED: 2,
  STEP_PAYMENT: 2,
  STEP_REVIEW: 3,
};

// Sort helper functions
const sortBy = {
  standName: (exhibitor: Exhibitor) => {
    return (
      (exhibitor?.stand?.name
        ?.split(/[\s,-]+/)
        .map((segment) =>
          segment === 'Tisch'
            ? undefined
            : Number(segment)
            ? segment.padStart(3, '0')
            : segment
        )
        .filter(Boolean) as string[]) || []
    );
  },
  dataCompleteness: (exhibitor) =>
    completeness[exhibitor?.registrationStatus] -
    (exhibitor?.registeredProducts?.totalCount > 0 ? 1 : 0),
};

export const fragment = gatsbyGraphQL/* GraphQL */ `
  fragment exhibitorMultiEventFilter on Exhibitor {
    event {
      eventId
      name
    }
  }
  fragment exhibitorFilter_Exhibitor on Exhibitor {
    isSeekingDistribution
  }
  fragment vinomed_exhibitorFilters on Bottlebooks_SingleRegistration {
    companyId
    stand {
      name
    }
    registrationStatus
    registeredProducts {
      totalCount
    }
    profile {
      name
      sortName
      countryName: country(format: LOCALIZED)
      region
      city
      distributionInCountries
      seekingDistributionInCountries
      # priceRanges
      sortName

      hospitalityOfferings
      outdoorExperienceOfferings
      wineExperienceOfferings
      lifestyleOfferings
    }
    vinomedRegion: customFieldValue(
      key: "dansQuelPaysRegionVotreDomaineEstIlSitue"
    ) {
      ...FieldValue
    }
  }
  #fragment exhibitorFilters_RegisteredBrand on Bottlebooks_RegisteredBrand {
  #  isSeekingDistribution
  #}
`;
