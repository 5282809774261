const getLinkBase = require('@bottlebooks/gatsby-theme-event/src/getLink');
const encodeFilters = require('@bottlebooks/gatsby-theme-event/src/encodeFilters');

module.exports = function getLink(context) {
  const link = getLinkBase(context);

  return {
    ...link,
    /**
     * Returns the path to the services list.
     * @param {object} [filters] Any filters on services.
     * @returns
     */
    services: (filters) =>
      context && `${path(context.base, 'services')}${encodeFilters(filters)}`,
    vinomed: (filters) =>
      context && `${path(context.base, 'vinomed')}${encodeFilters(filters)}`,
  };
};

// Duplicate logic from getLink
function path(...segments) {
  return `/${segments.filter(Boolean).join('/')}`.replace(/\/\/+/g, '/');
}
