/** @jsx jsx */
import {
  BookmarkIcon,
  Box,
  Button,
  CloseIcon,
  Container,
  Flex,
  HamburgerIcon,
  Link,
  SearchIcon,
  Text,
} from '@bottlebooks/gatsby-theme-base';
import { useFavorites } from '@bottlebooks/gatsby-theme-event/plugins/gatsby-plugin-favorites/src';
import Logo from '@bottlebooks/gatsby-theme-event/src/components/Layout/Logo';
import MenuItem from '@bottlebooks/gatsby-theme-event/src/components/Layout/MenuItem';
import { ShoppingCartIndicator } from '@bottlebooks/gatsby-theme-event/src/components/Shop/ShoppingCartMenu';
import SiteSearch from '@bottlebooks/gatsby-theme-event/src/components/SiteSearch/SiteSearch';
import useLink from '@bottlebooks/gatsby-theme-event/src/useLink';
import useSnipcart from '@bottlebooks/gatsby-theme-event/src/useSnipcart';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useState } from 'react';
import { jsx } from 'theme-ui';
import MenuItems from './MenuItems';

/**
 * @param {object} opt
 * @param {object[]} opt.menuItems
 * @param {string} opt.landingPage
 * @param {boolean} opt.isScrolledDown
 * @param {string} opt.locale
 * @returns
 */
export default function MainMenuMobile({
  menuItems,
  landingPage,
  isScrolledDown,
  locale,
  ...rest
}) {
  const [menuIsVisible, setMenuIsVisible] = useState(false);
  const [searchIsVisible, setSearchIsVisible] = useState(false);
  const link = useLink();

  return (
    <Container sx={{ zIndex: 9999 }} {...rest}>
      <Flex>
        <Flex direction="column" sx={{ width: '100%' }}>
          <Flex align="center">
            <Box>
              <Button
                variant="text"
                onClick={() => setMenuIsVisible(!menuIsVisible)}
              >
                {menuIsVisible ? <CloseIcon /> : <HamburgerIcon />}
              </Button>
            </Box>
            <Box sx={{ marginLeft: 3 }}>
              <Logo to={link.home()} isScrolledDown={isScrolledDown} />
            </Box>
            <Flex
              direction="row-reverse"
              align="center"
              sx={{ flexGrow: 1 }}
              {...rest}
            >
              {process.env.GATSBY_SNIPCART_API_KEY && (
                <ShoppingCartIndicator variant="mobile" />
              )}

              <Button
                variant="text"
                onClick={() => setSearchIsVisible(!searchIsVisible)}
                sx={{ marginRight: 2 }}
              >
                <SearchIcon sx={{ height: '30px', width: '30px' }} />
              </Button>
              <Button
                variant="primary"
                target="_blank"
                href={
                  locale === 'fr'
                    ? 'https://vinomed.myaccessbadge.net/index.php?&lang=FR'
                    : 'https://vinomed.myaccessbadge.net/index.php?&lang=UK'
                }
              >
                <Trans>Visitor's Badge</Trans>
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      {searchIsVisible && (
        <Search
          locale={locale}
          onClick={() => setSearchIsVisible(!searchIsVisible)}
        />
      )}
      {menuIsVisible && (
        <Menu
          menuItems={menuItems}
          onClick={() => setMenuIsVisible(!menuIsVisible)}
          locale={locale}
        />
      )}
    </Container>
  );
}

function Menu({ onClick, menuItems, locale }) {
  const { favorites: bookmarks } = useFavorites();
  const { openCart } = useSnipcart();
  const link = useLink();
  return (
    <Box
      sx={{
        position: 'fixed',
        paddingX: 2, // Match header padding
        paddingBottom: 3,
        zIndex: 'modal',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: 'light',
        overflowY: 'scroll',
      }}
    >
      <Flex direction="column">
        <Button
          variant="text"
          onClick={onClick}
          sx={{
            marginRight: 'auto',
            marginTop: 2.5,
            marginLeft: 2,
          }}
        >
          <CloseIcon />
        </Button>
        <Flex
          direction="column"
          sx={{
            flexWrap: 'wrap',
            alignItems: 'center',
          }}
        >
          <MenuItems />
          <MenuItem type="bookmarks" sx={{ position: 'relative' }}>
            <React.Fragment>
              <Text sx={{ marginRight: 1, display: 'inline-block' }}>
                <Trans>My list</Trans>
              </Text>
              <BookmarkIcon
                variant={bookmarks?.length ? 'inverted' : 'hollow'}
                size="small"
                sx={{
                  color: bookmarks?.length ? 'primary' : null,
                  marginLeft: 1,
                }}
              />

              {Boolean(bookmarks?.length) && (
                <Text
                  variant="small"
                  sx={{
                    display: 'inline-block',
                    // position: 'absolute',
                    marginLeft: 1,
                    verticalAlign: 'super',
                    marginTop: -3,
                    color: 'primary',
                  }}
                >
                  {bookmarks?.length}
                </Text>
              )}
            </React.Fragment>
          </MenuItem>
          {process.env.GATSBY_SNIPCART_API_KEY && (
            <MenuItem
              onClick={() => openCart()}
              // Conflict between menu and text variant requires manual override
              sx={{
                padding: 2,
                border: 0,
                backgroundColor: 'transparent',
              }}
            >
              <ShoppingCartIndicator variant="small">
                <Trans>Cart</Trans>
              </ShoppingCartIndicator>
            </MenuItem>
          )}
          <Box sx={{ marginTop: 2 }}>
            <Button
              variant="primary"
              target="_blank"
              href={
                locale === 'fr'
                  ? 'https://vinomed.myaccessbadge.net/index.php?&lang=FR'
                  : 'https://vinomed.myaccessbadge.net/index.php?&lang=UK'
              }
            >
              <Trans>Visitor's Badge</Trans>
            </Button>
          </Box>
          <Box sx={{ marginTop: 3 }}>
            <Link to="/en" sx={{ marginRight: 2 }}>
              EN
            </Link>
            <Link to="/fr" sx={{ marginRight: 2 }}>
              FR
            </Link>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
}

function Search({ onClick, locale }) {
  const { i18n } = useLingui();
  return (
    <Box
      sx={{
        position: 'fixed',
        paddingBottom: 3,
        zIndex: 'modal',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: 'light',
        overflowY: 'scroll',
      }}
    >
      {/* // Match header padding */}
      <Flex
        sx={{
          backgroundColor: 'background',
          paddingX: 2,
          paddingBottom: 2,
        }}
      >
        <Box>
          <Button
            variant="text"
            onClick={onClick}
            sx={{
              marginRight: 'auto',
              marginTop: 2.5,
              marginLeft: 2,
            }}
          >
            <CloseIcon />
          </Button>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <SiteSearch
            variant="large"
            locale={locale}
            placeholder={i18n._(t`Search for producer or wine…`)}
            sx={{ marginX: 2, marginTop: 2 }}
          />
        </Box>
      </Flex>
    </Box>
  );
}
