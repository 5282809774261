/** @jsx jsx */
import ProductsPage from '@bottlebooks/gatsby-theme-event/src/components/ProductsPage/ProductsPage';
import { graphql } from 'gatsby';
import deepMerge from 'lodash/merge';
import { jsx } from 'theme-ui';
import getActiveVinomedExhibitors from '../../../getActiveVinomedExhibitors';

export default function ProductsTemplate({
  data,
  pageContext,
  location,
  navigate,
}) {
  const { event, site, products } = afterQuery(data);
  const { filterPriceRange } = event || {};

  return (
    <ProductsPage
      {...pageContext}
      event={event}
      products={products}
      siteTitle={site?.siteMetadata?.title}
      facetConfig={{
        filterPriceRange,
        ...pageContext,
      }}
      location={location}
      navigate={navigate}
    />
  );
}

export const pageQuery = graphql`
  query vinomed_ProductsTemplate(
    $eventId: ID!
    $locale: Bottlebooks_ContentLocale
    $filter: ProductFilterInput
    $sort: ProductSortInput
    $localeString: String
  ) {
    bottlebooks {
      event(eventId: $eventId, locale: $locale) {
        collectionId: eventId
        ...bb_ProductsPageBase_Collection
        registeredProducts {
          nodes {
            productId
            ...bb_ProductsPageBase
          }
        }
      }
    }

    firstProducts: allProduct(filter: $filter, sort: $sort, limit: 10) {
      nodes {
        ...ProductsPage_first
      }
    }
    products: allProduct(filter: $filter, sort: $sort) {
      nodes {
        productId
        slug
        exhibitor {
          slug
        }
        ...ProductsPage
      }
    }
    event(locale: { eq: $localeString }) {
      ...ProductsPage_Event
      filterPriceRange
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;

function afterQuery({ bottlebooks, event, firstProducts, products, site }) {
  const bb_event = bottlebooks?.event;
  const bb_products = bb_event?.registeredProducts?.nodes;
  const combinedProducts = products.nodes?.map((product, index) => {
    const refinedData = firstProducts?.nodes[index];
    const bb_registeredProduct = bb_products?.find(
      ({ productId }) => productId === product.productId
    );
    const productMerged = deepMerge(
      {},
      product,
      bb_registeredProduct?.product,
      bb_registeredProduct,
      refinedData
    );
    return productMerged;
  });

  // Filter out anyone without a stand
  const filteredProducts = getActiveVinomedExhibitors(combinedProducts);
  return {
    event: { ...event, ...bb_event },
    site,
    products: filteredProducts,
  };
}
