/** @jsxRuntime classic */
import { Box, Flex, Link, Text } from '@bottlebooks/gatsby-theme-base';
import React from 'react';
import ReactCountryFlag from 'react-country-flag';

// This is a place for starter events to extend.
export default function TopMenuItems() {
  return (
    <Flex align="center" sx={{ marginRight: 3 }}>
      <Link variant="text" to="/en" sx={{ marginRight: 2 }}>
        <ReactCountryFlag countryCode="GB" title="English" />
      </Link>
      <Link variant="text" to="/fr" sx={{ marginRight: 2 }}>
        <ReactCountryFlag countryCode="FR" title="Français" />
      </Link>
    </Flex>
  );
}
