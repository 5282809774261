/**
 * Filters out exhibitors based on Vinomed rules.
 * Supports an array of any object that has a stand:{name} attribute.
 * @param {Array<{stand: {name: string}}>} exhibitors
 * @returns
 */
export default function getActiveVinomedExhibitors(exhibitors) {
  // Filter out anyone without a stand
  const filteredExhibitors = exhibitors.filter(
    (exhibitor) => !!exhibitor?.stand?.name
  );

  const enrichedExhibitors = filteredExhibitors.map((exhibitor) => {
    return {
      ...exhibitor,
      registrationType: !exhibitor?.stand?.name
        ? null
        : exhibitor?.stand?.name?.toLowerCase() === 'online'
        ? 'MARKETPLACE'
        : 'EXHIBITOR',
    };
  });
  return enrichedExhibitors;
}
