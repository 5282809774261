/** @jsx jsx */
import ProducersPage from '@bottlebooks/gatsby-theme-event/src/components/ProducersPage/ProducersPage';
import {
  extractProducerIdsForCharacteristics,
  getCharacteristicsForId,
} from '@bottlebooks/gatsby-theme-event-pages/src/helpers/brandCertifications';
import { graphql } from 'gatsby';
import deepMerge from 'lodash/merge';
import { jsx } from 'theme-ui';
import getActiveVinomedExhibitors from '../../../getActiveVinomedExhibitors';

export default function ProducersTemplate({
  data,
  pageContext,
  location,
  navigate,
}) {
  const { event, producers, site } = afterQuery(data);
  // This is how we should do this in the future - take the settings from event
  // But for the moment, we are loading them from environment variables using useSiteConfig
  // const { filterSeekingDistribution, filterHasDistribution } = event || {};
  return (
    <ProducersPage
      {...pageContext}
      event={event}
      producers={producers}
      facetConfig={{
        // This is how we should do this in the future - take the settings from event
        // But for the moment, we are loading them from environment variables using useSiteConfig
        // filterSeekingDistribution,
        // filterHasDistribution,
        ...pageContext,
      }}
      siteTitle={site?.siteMetadata?.title}
      location={location}
      navigate={navigate}
    />
  );
}

export const pageQuery = graphql`
  query vinomed_ProducersTemplate(
    $eventId: ID!
    $locale: Bottlebooks_ContentLocale
    $filter: ProducerFilterInput
    $sort: ProducerSortInput
    $localeString: String
  ) {
    bottlebooks {
      event(eventId: $eventId, locale: $locale) {
        producers {
          nodes {
            producerId
            ...bb_ProducersPage
          }
        }
        registeredBrands {
          nodes {
            ...bb_ProducersPage_RegisteredBrand
          }
        }
        # This would bring down the api server, so it's disabled by default.
        # registeredProducts {
        #   ...bb_BrandFilters_RegisteredProductConnection
        # }
      }
    }
    event(locale: { eq: $localeString }) {
      ...ProducersTemplate_Event
    }
    firstProducers: allProducer(filter: $filter, sort: $sort, limit: 100) {
      nodes {
        ...ProducersPage_first
      }
    }
    producers: allProducer(filter: $filter, sort: $sort) {
      nodes {
        producerId
        exhibitorId
        ...ProducersPage
        ...producerFilter_Producer
        ...producerMultiEventFilter
      }
    }
    ...ProducersTemplate_Query
  }

  fragment ProducersTemplate_Event on Event {
    # This is how we should do this in the future - take the settings from event
    # But for the moment, we are loading them from environment variables using useSiteConfig
    # filterSeekingDistribution
    # filterHasDistribution
    ...ProducersPage_Event
  }

  fragment ProducersTemplate_Query on Query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

function afterQuery({ bottlebooks, event, firstProducers, producers, site }) {
  const bb_event = bottlebooks?.event;
  const bb_producers = bb_event?.producers?.nodes;
  const bb_registeredBrands = bb_event?.registeredBrands?.nodes;
  const combinedProducers = producers.nodes?.map((producer, index) => {
    const refinedData = firstProducers?.nodes[index];
    const bb_producer = bb_producers?.find(
      ({ producerId }) => producerId === producer.producerId
    );
    const bb_registeredBrand = bb_registeredBrands?.find(
      ({ profile: { brandId: producerId } }) =>
        producerId === producer.producerId
    );
    return deepMerge(
      {},
      producer,
      bb_producer,
      bb_registeredBrand,
      refinedData,
      {
        characteristics: getCharacteristicsForId({
          extractIdsFn: extractProducerIdsForCharacteristics,
          id: producer.producerId,
          event: bb_event,
        }),
      }
    );
  });

  // Filter out anyone without a stand
  const filteredProducers = getActiveVinomedExhibitors(combinedProducers);

  return {
    event,
    site,
    producers: filteredProducers,
  };
}
