/** @jsx jsx */
import { Box, Button, Container, Flex } from '@bottlebooks/gatsby-theme-base';
import Logo from '@bottlebooks/gatsby-theme-event/src/components/Layout/DesktopLogo';
import MenuActions from '@bottlebooks/gatsby-theme-event/src/components/Layout/MenuActions';
import SiteSearch from '@bottlebooks/gatsby-theme-event/src/components/SiteSearch/SiteSearch';
import useLink from '@bottlebooks/gatsby-theme-event/src/useLink';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { jsx } from 'theme-ui';
import MenuItems from './MenuItems';

/**
 * @param {object} props
 * @param {object[]} props.menuItems
 * @param {string} props.landingPage
 * @param {boolean} [props.hideSearch]
 * @param {boolean} [props.isScrolledDown]
 * @param {string} props.locale
 * @returns
 */
export default function MainMenuDesktop({
  menuItems,
  landingPage,
  isScrolledDown,
  hideSearch,
  locale,
  ...rest
}) {
  const { i18n } = useLingui();
  const link = useLink();
  return (
    <Container fluid sx={{ maxWidth: 'container.fluid' }} {...rest}>
      <Flex align="center">
        <Box sx={{ marginRight: 3 }}>
          <Logo to={link.home()} isScrolledDown={isScrolledDown} />
        </Box>
        <Flex direction="column" sx={{ flexGrow: 1 }}>
          <Flex align="center" sx={{ height: '64px' }}>
            {!hideSearch && (
              <Box sx={{ position: 'relative' }}>
                <SiteSearch
                  variant="large"
                  locale={locale}
                  placeholder={i18n._(t`Search for producer or wine…`)}
                  sx={{
                    width: '300px',
                    marginLeft: 2,
                    backgroundColor: 'light',
                  }}
                />
              </Box>
            )}
            <MenuActions />
          </Flex>
          <Flex
            align="center"
            sx={{ justifyContent: 'space-between', height: '64px' }}
          >
            <Box>
              <MenuItems />
            </Box>
            <Box>
              <Button
                variant="primary"
                target="_blank"
                href={'https://salon-vinomed.com/visiteurs/#demande-de-badge'}
              >
                <Trans>Visitor's Badge</Trans>
              </Button>
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Container>
  );
}
