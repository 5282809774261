// @ts-check
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import {
  Box,
  Container,
  Flex,
  Grid,
  Stack,
} from '@bottlebooks/gatsby-theme-base';
import { defineMessage } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { graphql as gatsbyGraphQL } from 'gatsby';
import React from 'react';
import { jsx } from 'theme-ui';
import { ExhibitorsList } from '@bottlebooks/gatsby-theme-event/src/components/ExhibitorsList';
import ActiveFiltersBar from '@bottlebooks/gatsby-theme-event/src/components/Filters/ActiveFiltersBar';
import FilterField from '@bottlebooks/gatsby-theme-event/src/components/Filters/FilterField';
import {
  filtersCleared,
  filterToggled,
  searchTermChanged,
  sortChanged,
} from '@bottlebooks/gatsby-theme-event/src/components/Filters/filterReducer';
import Filters, {
  useFiltersDisplay,
} from '@bottlebooks/gatsby-theme-event/src/components/Filters/Filters';
// eslint-disable-next-line import/no-unresolved
import useIndex from '@bottlebooks/gatsby-theme-event/src/components/Filters/useIndex';
import useLocationSyncedState from '@bottlebooks/gatsby-theme-event/src/components/Filters/useLocationSyncedState';
import Layout from '@bottlebooks/gatsby-theme-event/src/components/Layout';
// import DownloadMyBookmarks from '@bottlebooks/gatsby-theme-event/src/components/ProductsPage/DownloadMyBookmarks';
import Sort from '@bottlebooks/gatsby-theme-event/src/components/ProductsPage/Sort';
import SEO from '@bottlebooks/gatsby-theme-event/src/components/seo.js';
import EmptyState from '@bottlebooks/gatsby-theme-event/src/components/ExhibitorsPage/EmptyState';
// eslint-disable-next-line import/no-unresolved
import useExhibitorFilters from '@bottlebooks/gatsby-theme-event/src/components/ExhibitorsPage/exhibitorFilters';
import ExhibitorsPageCards from '@bottlebooks/gatsby-theme-event/src/components/ExhibitorsPage/ExhibitorsPageCards';
import ExhibitorsPageTitle from '@bottlebooks/gatsby-theme-event/src/components/ExhibitorsPage/ExhibitorsPageTitle';
import useExhibitorSearch from '@bottlebooks/gatsby-theme-event/src/components/ExhibitorsPage/useExhibitorSearch';
import { pick, omit } from 'lodash';

const PAGE_SIZE = 30;

export default function ExhibitorsPage({
  exhibitors,
  collectionId,
  location,
  locale,
}) {
  const { i18n } = useLingui();
  const baseFilterConfig = useExhibitorFilters();
  const tourismFilters = [
    'wineExperienceOfferings',
    'hospitalityOfferings',
    'outdoorExperienceOfferings',
    'lifestyleOfferings',
  ];
  const isServicesPage = location.pathname.includes('/services');
  const aggregations = isServicesPage
    ? pick(baseFilterConfig.aggregations, tourismFilters)
    : omit(baseFilterConfig.aggregations, tourismFilters);

  const filterConfig = {
    ...baseFilterConfig,
    aggregations,
  };

  const [state, dispatch] = useLocationSyncedState(location);
  const searched = useExhibitorSearch(exhibitors, state.searchTerm?.toString()); // TODO (solve differently) with SSR, sometimes the searchTerm is an array.
  const sortings = Object.entries(filterConfig.sortings).map(
    ([key, sorting]) => ({ key, ...sorting })
  );
  const activeSorting =
    sortings.find((sorting) => sorting.key === state.sort) || sortings[0];
  const sort = activeSorting.key;

  const { getToggleProps, getFiltersProps, isVisibleOnDesktop } =
    useFiltersDisplay();

  // Filter the exhibitors by term and facets.
  const [limit, setLimit] = React.useState(PAGE_SIZE);
  const index = useIndex(searched, filterConfig);
  const filters = Object.fromEntries(
    Object.keys(filterConfig.aggregations).map((key) => [key, state[key] || []])
  );
  const { data, pagination } = index.search({ per_page: limit, sort, filters });
  // console.log(filterConfig, data, pagination, timings);
  const showMore = React.useCallback(
    () => setLimit((limit) => limit + PAGE_SIZE),
    []
  );
  /**
   * @param {{key: string; doc_count: number; selected: boolean; }} bucket
   */
  function toggleFilter(aggregation, bucket) {
    dispatch(filterToggled(aggregation.name, bucket.key));
    setLimit(PAGE_SIZE);
    // if (!bucket.selected) setFiltersAreVisible(false);
  }
  const mainColumn = {
    gridColumnStart: [1, isVisibleOnDesktop ? 2 : 1],
    gridColumnEnd: -1,
  };

  const hasMore = pagination.total > data.items.length;
  const page = { path: location.href };
  return (
    <Layout locale={locale}>
      <SEO
        page={page}
        title={i18n._(defineMessage({ message: `Exhibitors` }))}
      />
      <Box sx={{ backgroundColor: 'light' }}>
        <Container fluid sx={{ paddingTop: 3, paddingBottom: 6 }}>
          <Stack>
            <ActiveFiltersBar
              searchTerm={state.searchTerm}
              aggregations={data.aggregations}
              definitions={filterConfig.aggregations}
              onToggleFilter={toggleFilter}
              onClearFilters={() => dispatch(filtersCleared())}
            />
            <Flex
              gap={2}
              align="baseline"
              justify="space-between"
              sx={{ flexDirection: ['column', 'row'] }}
            >
              <ExhibitorsPageTitle
                totalCount={exhibitors.length}
                count={pagination.total}
              />
              {/* <DownloadMyBookmarks
                collectionId={collectionId}
                locale={locale}
              /> */}
            </Flex>
            <Grid
              width={254}
              repeat="fill"
              sx={{ alignItems: 'center', gridAutoFlow: 'dense' }}
            >
              <Sort
                sortings={sortings}
                value={sort}
                onChange={(value) => dispatch(sortChanged(value))}
                sx={{ gridColumn: '-2/-1', textAlign: 'right' }}
              />
              <FilterField
                placeholder={i18n._(
                  defineMessage({ message: 'Enter wine or producer name' })
                )}
                value={state.searchTerm}
                onChange={(value) => {
                  setLimit(PAGE_SIZE);
                  return dispatch(searchTermChanged(value));
                }}
                variant="large"
                sx={{
                  borderColor: 'borderSecondary',
                  gridColumn: ['1/-1', '2/-2'],
                }}
              />
              <Filters.ToggleDesktop {...getToggleProps()} />
              <Filters.ToggleMobile {...getToggleProps()} />
            </Grid>
            <Grid width={254} repeat="fill" sx={{ alignItems: 'flex-start' }}>
              <Filters
                key={state.searchTerm}
                aggregations={data.aggregations}
                definitions={filterConfig.aggregations}
                onToggleFilter={toggleFilter}
                totalCount={exhibitors.length}
                count={pagination.totalCount}
                sx={{
                  // Copied from online directory code
                  // Keeps menu on screen
                  maxHeight: [null, '100vh'],
                  overflowY: [null, 'auto'],
                  position: [null, 'sticky'],
                  top: 0,
                }}
                {...getFiltersProps()}
              />

              {!pagination.total ? (
                <EmptyState sx={mainColumn} />
              ) : (
                <ExhibitorsList
                  hasMore={hasMore}
                  showMore={showMore}
                  width={254}
                  sx={{ gridAutoFlow: 'dense', ...mainColumn }}
                >
                  <ExhibitorsPageCards
                    exhibitors={data.items}
                    group={activeSorting.group}
                    groupHeader={activeSorting.groupHeader}
                  />
                </ExhibitorsList>
              )}
            </Grid>
          </Stack>
        </Container>
      </Box>
    </Layout>
  );
}

export const fragment = gatsbyGraphQL/* GraphQL */ `
  fragment bb_ExhibitorsPage on Bottlebooks_Exhibitor {
    exhibitorId
    ...bb_ExhibitorCard
    ...useLink_bb_Exhibitor
  }

  fragment bb_ExhibitorsPage_RegisteredBrand on Bottlebooks_RegisteredBrand {
    ...bb_ExhibitorCard_RegisteredBrand
  }

  fragment ExhibitorsPage_first on Exhibitor {
    ...ExhibitorCard_first
  }

  fragment ExhibitorsPage on Exhibitor {
    exhibitorId
    ...ExhibitorCard
  }

  fragment ExhibitorsPage_Event on Event {
    ...EventBanner
  }
`;
