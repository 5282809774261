/** @jsx jsx */
import EventBannerImage from '@bottlebooks/gatsby-theme-event/src/components/Event/EventBannerImage';
import { graphql } from 'gatsby';
import gql from 'graphql-tag';
import React from 'react';
import { jsx } from 'theme-ui';

export default function EventBanner({ event, withOverlay = true, ...rest }) {
  return (
    <React.Fragment>
      <img
        src={event.mainImage?.fixed?.src}
        alt={event.name}
        sx={{
          display: ['none', null, 'block'],
          position: 'relative',
          objectFit: 'contain',
          // Always ensure the images are full-width
          width: '100%',
          height: '100%',
        }}
      />
      <img
        src={event.files?.nodes[0]?.fixed.src}
        alt={event.name}
        sx={{
          display: ['block', null, 'none'],
          position: 'relative',
          objectFit: 'contain',
          // Always ensure the images are full-width
          width: '100%',
          height: '100%',
        }}
      />
    </React.Fragment>
  );
}

export const fragment = graphql`
  fragment vinomed_bb_EventBanner on Bottlebooks_Event {
    name
    # Desktop image
    mainImage {
      fixed {
        src
      }
    }
    # Mobile image -- assumes it is the first image
    files {
      nodes {
        fixed(width: 640) {
          src
        }
      }
    }
    ...bb_EventBannerImage
  }
  fragment EventBanner on Event {
    name
    ...EventBannerImage
  }
`;
EventBanner.fragment = gql`
  fragment vinomed_EventBanner on Event {
    name
    # Desktop image
    mainImage {
      fixed {
        src
      }
    }
    # Mobile image -- assumes it is the first image
    files {
      nodes {
        fixed(width: 640) {
          src
        }
      }
    }
    ...EventBannerImage
  }
  ${EventBannerImage.fragment}
`;
