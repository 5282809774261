/** @jsx jsx */
import {
  extractPartnerIdsForCharacteristics,
  getCharacteristicsForId,
} from '@bottlebooks/gatsby-theme-event-pages/src/helpers/brandCertifications';
import ExhibitorsPage from '@bottlebooks/gatsby-theme-event/src/components/ExhibitorsPage/ExhibitorsPage';
import { graphql } from 'gatsby';
import deepMerge from 'lodash/merge';
import { jsx } from 'theme-ui';
import getActiveVinomedExhibitors from '../getActiveVinomedExhibitors';

export default function ServicesTemplate({
  data,
  pageContext,
  location,
  navigate,
}) {
  const { event, exhibitors, site } = afterQuery(data);
  // This is how we should do this in the future - take the settings from event
  // But for the moment, we are loading them from environment variables using useSiteConfig
  // const { filterSeekingDistribution, filterHasDistribution } = event || {};
  return (
    <ExhibitorsPage
      {...pageContext}
      event={event}
      exhibitors={exhibitors}
      siteTitle={site?.siteMetadata?.title}
      location={location}
      navigate={navigate}
      services
    />
  );
}

export const pageQuery = graphql`
  query ServicesTemplate(
    $eventId: ID!
    $locale: Bottlebooks_ContentLocale
    $filter: ExhibitorFilterInput
    $sort: ExhibitorSortInput
    $localeString: String
  ) {
    bottlebooks {
      event(eventId: $eventId, locale: $locale) {
        ...vinomed_ExhibitorsTemplate_Bottlebooks_Event
      }
    }
    event(locale: { eq: $localeString }) {
      ...ExhibitorsTemplate_Event
    }
    firstExhibitors: allExhibitor(filter: $filter, sort: $sort, limit: 10) {
      nodes {
        ...ExhibitorsPage_first
      }
    }
    exhibitors: allExhibitor(filter: $filter, sort: $sort) {
      nodes {
        exhibitorId
        ...ExhibitorsPage
        ...exhibitorFilter_Exhibitor
        ...exhibitorMultiEventFilter
      }
    }
    ...ExhibitorsTemplate_Query
  }

  fragment ExhibitorsTemplate_Event on Event {
    # This is how we should do this in the future - take the settings from event
    # But for the moment, we are loading them from environment variables using useSiteConfig
    # filterSeekingDistribution
    # filterHasDistribution
    ...ExhibitorsPage_Event
  }

  fragment ExhibitorsTemplate_Query on Query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

function afterQuery({ bottlebooks, event, firstExhibitors, exhibitors, site }) {
  const bb_event = bottlebooks?.event;
  const bb_exhibitors = bb_event?.exhibitors?.nodes;
  const bb_registeredBrands = bb_event?.registeredBrands?.nodes;
  const bb_registrations = bb_event?.registrations?.nodes;
  // Enrich the first exhibitors with better images and base64 previews.
  const combinedExhibitors = exhibitors.nodes?.map((exhibitor, index) => {
    const refinedExhibitor = firstExhibitors?.nodes[index];
    const bb_exhibitor = bb_exhibitors?.find(
      ({ exhibitorId }) => exhibitorId === exhibitor.exhibitorId
    );
    const bb_registeredBrand = bb_registeredBrands?.find(
      ({ partnerId }) => partnerId === exhibitor.exhibitorId
    );
    const bb_registration = bb_registrations?.find(
      ({ companyId }) => companyId === exhibitor.exhibitorId
    );

    return deepMerge(
      {},
      exhibitor,
      bb_exhibitor,
      bb_registeredBrand,
      bb_registration,
      refinedExhibitor,
      {
        characteristics: getCharacteristicsForId({
          extractIdsFn: extractPartnerIdsForCharacteristics,
          id: exhibitor.exhibitorId,
          event: bb_event,
        }),
      }
    );
  });

  const activeExhibitors = getActiveVinomedExhibitors(combinedExhibitors);

  const filteredExhibitors = activeExhibitors.filter((exhibitor) => {
    return (
      exhibitor.profile?.wineExperienceOfferings?.length > 0 ||
      exhibitor.profile?.hospitalityOfferings?.length > 0 ||
      exhibitor.profile?.outdoorExperienceOfferings?.length > 0 ||
      exhibitor.profile?.lifestyleOfferings?.length > 0
    );
  });

  return {
    event,
    site,
    exhibitors: filteredExhibitors,
  };
}
