// @ts-check
/** @jsx jsx */
import LandingPage from '@bottlebooks/gatsby-theme-event/src/components/LandingPage/LandingPage';
import { graphql } from 'gatsby';
import uniqBy from 'lodash/uniqBy';
import React from 'react';
import { jsx } from 'theme-ui';
import getActiveVinomedExhibitors from '../../../getActiveVinomedExhibitors';

export default function LandingTemplate({ data, pageContext, location }) {
  const { featuredExhibitors, fallbackExhibitors, event, locales } =
    afterQuery(data);
  const { minFeaturedExhibitors, limit, locale } = pageContext;
  const localeConfig = locales.find(
    (/** @type {{ locale: any; }} */ localeConfig) =>
      localeConfig.locale === locale
  );

  // Filter out anyone without a stand
  const filteredExhibitors = getActiveVinomedExhibitors(featuredExhibitors);
  const exhibitors = React.useMemo(() => {
    if (filteredExhibitors.length > minFeaturedExhibitors) {
      return filteredExhibitors.slice(0, limit);
    }
    return uniqBy(
      [...filteredExhibitors, ...fallbackExhibitors],
      ({ brandId }) => brandId
    ).slice(0, limit);
  }, [filteredExhibitors, fallbackExhibitors, minFeaturedExhibitors, limit]);
  return (
    <LandingPage
      {...pageContext}
      event={event}
      localeConfig={localeConfig}
      exhibitors={exhibitors}
      producers={[]}
      location={location}
    />
  );
}

export const pageQuery = graphql`
  query vinomed_LandingTemplate(
    $eventId: ID!
    $locale: Bottlebooks_ContentLocale
    $filter: ExhibitorFilterInput
    $sort: ExhibitorSortInput
    $limit: Int = 100
    $localeString: String
  ) {
    bottlebooks {
      event(eventId: $eventId, locale: $locale) {
        ...vinomed_bb_EventBanner
        ...bb_LandingPage
      }
    }
    featuredExhibitors: allExhibitor(
      filter: $filter
      sort: $sort
      limit: $limit
    ) {
      nodes {
        brandId
        ...LandingPage_FeaturedExhibitors
      }
    }
    fallbackExhibitors: allExhibitor(
      filter: {
        locale: { eq: $localeString }
        mainImage: { url: { ne: null } }
        logo: { url: { ne: null } }
      }
      sort: { fields: [contentLevel], order: [DESC] }
      limit: $limit
    ) {
      nodes {
        ...LandingPage_FeaturedExhibitors
      }
    }
    site {
      siteMetadata {
        locales {
          locale
          ...LandingPage_LocaleConfig
        }
      }
    }
  }
`;

function afterQuery(data) {
  return {
    featuredExhibitors: data.featuredExhibitors?.nodes ?? [],
    fallbackExhibitors: data.fallbackExhibitors?.nodes ?? [],
    event: { ...data?.event, ...data?.bottlebooks?.event },
    locales: data.site.siteMetadata.locales,
  };
}
